@import './lib/scss/breakpoints.scss';

body {
  font-family: 'Noto Sans JP', sans-serif, 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック',
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #2b2b2b;
  font-size: 16px;
  @mixin sm-only {
    font-size: 13px;
  }
}

a {
  text-decoration: none;
  transition: all 0.1s ease;
  color: #2b2b2b;
}

a:hover {
  color: #5d2f91;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  font-size: 16px;
  line-height: 1.6;
}
